import { PlusSmIcon, MinusSmIcon } from "@heroicons/react/outline";
import Accordion from "../Accordion";
import { MenuItemsData } from "../TopHeaderMenuItems/Interface";
import Link from "next/link";
import { SyntheticEvent, useEffect, useState } from "react";
import BlogSubscribeModal from "../BlogSubscribeModal";
import React from "react";
import { useRouter } from "next/router";
import menuDetails from "../MegaMenu/Data";
import { menuItemDetails } from "../MenuItems/Data";
import {
  BootcampsData,
  EnterpriseData,
  ResourcesData,
} from "../TopHeaderMenuItems/Data";
import { ChevronDownIcon } from "@heroicons/react/solid";
import HeaderSidebarContactUs from "./HeaderSidebarContactUs";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const HeaderLeftSideBar: React.FC<any> = ({
  sideBar,
  side,
  closeAllSideBars,
  courseID,
}) => {
  const router = useRouter();

  const [currenetPath, setCurrentPath] = useState<string>(router.asPath);

  // Accordion style
  const AccordionBodyStyle = `flex items-center border-b border-gray-200 bg-gray-100 text-gray-10 text-13 font-semibold p-4`;

  // Accordion body
  const [showBody, setShowBody] = useState<string>("");

  // Accordion Course body
  const [showCourses, setShowCourses] = useState<string>("");

  // Sidebar accordions (Courses, More, Help) open/close
  const changeBodyStatus: any = (title: string) => {
    setShowBody(showBody === title ? "" : title);
  };

  // Courses accordions open/close
  const changeCourseStatus: any = (title: string) => {
    setShowCourses(showCourses === title ? "" : title);
  };

  // State for Subscribe modal
  const [open, setOpen] = useState(false);

  // Closes Sidebar
  const closeSideBar = () => {
    closeAllSideBars();
  };

  // Checks for click on "overlay"
  const handleClose = (e: any) => {
    if (e.target?.id === "overlay") {
      closeSideBar();
    }
  };

  // Subscribe modal
  const openSubscribeModal = () => {
    closeSideBar();
    setOpen(!open);
  };

  // Disabling / Enabling Scroll Overflow for <body>
  useEffect(() => {
    document.body.style.overflow = sideBar ? "hidden" : "auto";
  }, [sideBar]);

  return (
    <div
      className={`fixed w-screen inset-0 bg-transparent
        ${sideBar === null ? "opacity-0" : "opacity-100"}
        ${sideBar && side === "left" ? "translate-x-0" : "-translate-x-full"}
        transform transition ease-in-out duration-500 bg-transparent z-50 `}
      id="overlay"
      onClick={(e: SyntheticEvent) => handleClose(e)}
    >
      <div
        className={`fixed bg-white inset-0 ${
          sideBar && side === "left" ? "translate-x-0" : "-translate-x-full"
        } transform transition ease-in-out duration-500 bg-white z-50`}
      >
        <BlogSubscribeModal open={open} setOpen={setOpen} courseID={courseID} />
        {sideBar && side === "left" && (
          <>
            {/* Heading with Close icon */}
            <div className="sticky bg-gray-14 top-0 left-0 flex justify-between items-center h-51p w-full p-4 border-b border-gray-300">
              <div className="text-sm font-bold leading-5">Menu</div>
              <div
                className="cursor-pointer hover:bg-gray-100 rounded-full px-1 mr-px"
                onClick={closeSideBar}
              >
                <i className="blog-icons icon-close inline-block"></i>
              </div>
            </div>

            {/* Accordions & Links for Megamenu & Menuitems */}
            <div className="fixed flex flex-col justify-between top-51p left-0 right-0 bottom-0">
              <div className="bg-white z-50 overflow-auto customized-scrollbar">
                {/* Accordions for Blogs */}
                <Accordion
                  title="All Blogs"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                >
                  {menuItemDetails?.map(
                    ({ title, ref }: MenuItemsData, index: number) => (
                      <div
                        className={AccordionBodyStyle}
                        key={index}
                        onClick={closeSideBar}
                      >
                        <Link href={ref}>
                          <a className="w-full text-sm">{title}</a>
                        </Link>
                        {/* <i className=" pl-6 icon-right-arrow-small blog-icons inline-block"></i> */}
                        <div>
                          <ChevronDownIcon className="w-5 h-5 -rotate-90" />
                        </div>
                      </div>
                    )
                  )}
                </Accordion>

                {/* Accordions for MegaMenu */}
                <Accordion
                  title="All Courses"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                  sticky={showBody === "Courses" ? true : false}
                >
                  {/* Accordions for Courses in MegaMenu */}
                  {menuDetails?.map((categoryItem: any, index: number) => (
                    <Accordion
                      title={categoryItem.name}
                      icon={<PlusSmIcon />}
                      icon2={<MinusSmIcon />}
                      leftBorder={true}
                      key={index}
                      showBody={showCourses}
                      changeBodyStatus={changeCourseStatus}
                      ga4EventKey={`mega_menu_click_menu_items{${categoryItem.name}}`}
                    >
                      {categoryItem.courses?.map((item: any, index: number) => (
                        <div
                          className={AccordionBodyStyle}
                          key={index}
                          onClick={closeSideBar}
                        >
                          <Link href={item.courseUrl}>
                            <a
                              className="w-full ml-2 text-13 text-gray-10 font-normal"
                              dangerouslySetInnerHTML={{
                                __html: item.courseName,
                              }}
                              onClick={() => {
                                trackGa4Events(
                                  `mega_menu_click_course_menu_items{${item.courseName}}`,
                                  {
                                    parameters: { category: categoryItem.name },
                                  }
                                );
                              }}
                            ></a>
                          </Link>
                        </div>
                      ))}
                      {categoryItem.courses.length >= 10 && (
                        <div className={AccordionBodyStyle} key={index}>
                          <Link href={categoryItem.link}>
                            <a className="w-full ml-2">
                              <div
                                className="flex items-center justify-between"
                                onClick={closeSideBar}
                              >
                                <div className="text-pink-2 text-13 font-medium">
                                  View All Courses
                                </div>
                                <div>
                                  <i className="inline-block icon-right-arrow-pink blog-icons"></i>
                                </div>
                              </div>
                            </a>
                          </Link>
                        </div>
                      )}
                    </Accordion>
                  ))}
                </Accordion>

                {/* Accordion for MenuItems Boot camp */}
                <Accordion
                  title="Bootcamps"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                >
                  {BootcampsData?.map(
                    ({ title, ref }: MenuItemsData, index: number) => (
                      <div
                        className={AccordionBodyStyle}
                        key={index}
                        onClick={closeSideBar}
                      >
                        <Link href={ref}>
                          <a className="w-full text-sm">{title}</a>
                        </Link>
                      </div>
                    )
                  )}
                </Accordion>

                {/* Accordion for Enterprise Menu item */}
                <Accordion
                  title="Enterprise"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                >
                  {EnterpriseData?.map(
                    ({ title, ref, data }: MenuItemsData, index: number) =>
                      data ? (
                        <Accordion
                          title={title}
                          icon={<PlusSmIcon />}
                          icon2={<MinusSmIcon />}
                          leftBorder={true}
                          key={index}
                          showBody={showCourses}
                          changeBodyStatus={changeCourseStatus}
                        >
                          {data?.map(
                            ({ title, ref }: MenuItemsData, index: number) => (
                              <div
                                className={AccordionBodyStyle}
                                key={index}
                                onClick={closeSideBar}
                              >
                                <Link href={ref}>
                                  <a
                                    className="w-full ml-2 text-sm"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                  ></a>
                                </Link>
                              </div>
                            )
                          )}
                        </Accordion>
                      ) : (
                        <div
                          className={AccordionBodyStyle}
                          key={index}
                          onClick={closeSideBar}
                        >
                          <Link href={ref}>
                            <a
                              className="w-full text-sm"
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></a>
                          </Link>
                        </div>
                      )
                  )}
                </Accordion>
                {/* Accordion for Resources Menu item */}
                <Accordion
                  title="Resources"
                  showBody={showBody}
                  changeBodyStatus={changeBodyStatus}
                >
                  {ResourcesData?.map(
                    ({ title, ref, data }: MenuItemsData, index: number) =>
                      data ? (
                        <Accordion
                          title={title}
                          icon={<PlusSmIcon />}
                          icon2={<MinusSmIcon />}
                          leftBorder={true}
                          key={index}
                          showBody={showCourses}
                          changeBodyStatus={changeCourseStatus}
                        >
                          {data?.map(
                            ({ title, ref }: MenuItemsData, index: number) => (
                              <div
                                className={AccordionBodyStyle}
                                key={index}
                                onClick={closeSideBar}
                              >
                                <Link href={ref}>
                                  <a
                                    className="w-full ml-2 text-sm"
                                    dangerouslySetInnerHTML={{ __html: title }}
                                  ></a>
                                </Link>
                              </div>
                            )
                          )}
                        </Accordion>
                      ) : (
                        <div
                          className={AccordionBodyStyle}
                          key={index}
                          onClick={closeSideBar}
                        >
                          <Link href={ref}>
                            <a
                              className="w-full text-sm"
                              dangerouslySetInnerHTML={{ __html: title }}
                            ></a>
                          </Link>
                        </div>
                      )
                  )}
                </Accordion>

                {/* Accordion for Help Option */}
                {/* <Accordion
              title="Help"
              showBody={showBody}
              changeBodyStatus={changeBodyStatus}
            >
              <div>
                <div className={AccordionBodyStyle} onClick={closeSideBar}>
                  <Link href="">
                    <a className="w-full py-5 px-3 text-sm">FAQ</a>
                  </Link>
                </div>
                <div className={AccordionBodyStyle} onClick={closeSideBar}>
                  <Link href="">
                    <a className="w-full py-5 px-3 text-sm">Contact Us</a>
                  </Link>
                </div>
              </div>
            </Accordion> */}
              </div>
              {/* </div> */}

              {/* Subscribe & SignIn buttons */}
              <HeaderSidebarContactUs openSubscribeModal={openSubscribeModal} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderLeftSideBar;
