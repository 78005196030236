import axios from "axios";
import type { AppProps, NextWebVitalsMetric } from "next/app";
import { useRouter } from "next/router";
import { createContext, useEffect, useState } from "react";
import * as gtag from "../lib/gtag";
import dynamic from "next/dynamic";
import Image from "next/image";
import Header from "../components/Header";
import "../styles/globals.css";
import { ToastContainer } from "react-toastify";
import ToastifyContainer from "../components/ToastifyContainer";
import "react-toastify/dist/ReactToastify.min.css";
import "intersection-observer";
import getConfig from "next/config";
import "../styles/custom.css";
import { tracker } from "../lib/events/rudder-stack/tracker";
import { getPageCategory } from "../lib/common/common";
import {
  trackPageLoaded,
  trackPopupClosed,
  trackPhoneIconClick,
  trackChatIconClick,
  trackChatExpensionClick,
  trackWhatsappClick,
  trackHandleChatbotClose,
} from "../lib/events/rudder-stack/trackingEvents";
import isBotUser from "../lib/checkBot";

declare const window: any;

const { publicRuntimeConfig } = getConfig();

axios.defaults.baseURL = publicRuntimeConfig.AXIOS_API;

// export function reportWebVitals(metric: NextWebVitalsMetric) {
//   switch (metric.name) {
//     case "FCP":
//       console.log("FCP" + JSON.stringify(metric));
//       break;
//     case "LCP":
//       console.log("LCP" + JSON.stringify(metric));
//       break;
//     case "CLS":
//       console.log("CLS" + JSON.stringify(metric));
//       break;
//     case "FID":
//       console.log("FID" + JSON.stringify(metric));
//       break;
//     case "TTFB":
//       console.log("TTFB" + JSON.stringify(metric));
//       break;
//     case "Next.js-hydration":
//       console.log("Next.js-hydration" + JSON.stringify(metric));
//       break;
//     case "Next.js-route-change-to-render":
//       console.log("Next.js-route-change-to-render" + JSON.stringify(metric));
//       break;
//     case "Next.js-render":
//       console.log("Next.js-render" + JSON.stringify(metric));
//       break;
//     default:
//       break;
//   }
// }

const DynamicFooter = dynamic(() => import("../components/Footer/index"));

// formsContext
export const FormsContext = createContext<{
  ga4EventKey: any;
  setGa4EventKey: any;
}>({ ga4EventKey: "", setGa4EventKey: "" });

interface AppContextValue {
  pageProps?: any;
}
export const AppContext = createContext<AppContextValue>({});

function MyApp({ Component, pageProps }: AppProps) {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
  };

  const router = useRouter();
  const isBot =
    typeof pageProps?.userAgent == "string"
      ? isBotUser(pageProps?.userAgent)
      : false;
  useEffect(() => {
    const handleRouteChange = (url: any) => {
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    trackPageLoaded();
  }, []);

  const [moengageName, setMoengageName] = useState("");
  const [ga4EventKey, setGa4EventKey] = useState("");
  const [shortenUrl, setShortenUrl] = useState<string | null>(null);
  const [WA_MESSAGE, setWAMessage] = useState<string>(
    "I am ready to accelerate my career growth by upskilling"
  );
  const [alias, setAlias] = useState<string>("");
  const [encodedAliasMsg, setEncodedAliasMsg] = useState<string>("");

  async function sendWAMessage() {
    if (shortenUrl === null) {
      await axios
        .post(`${process.env.NEXT_PUBLIC_CREATE_SHORT_URL_API}`, {
          url: window.location.href,
        })
        .then(function (result) {
          if (result.status === 200) {
            setAlias(" P-" + result.data.data.alias);
            setShortenUrl(result.data.data.alias);
          }
        })
        .catch((error) => {
          console.error("Error while shortening URL:", error);
        });
    }
  }

  useEffect(() => {
    sendWAMessage();
  }, []);

  useEffect(() => {
    if (alias) {
      setEncodedAliasMsg(encodeURIComponent(WA_MESSAGE + alias));
    }
  }, [alias]);

  useEffect(() => {
    const handleChatbotClose = () => {
      trackHandleChatbotClose();
    };
    document.addEventListener("chatbotClose", handleChatbotClose);
    return () => {
      document.removeEventListener("chatbotClose", handleChatbotClose);
    };
  }, []);

  // capture pageCategory for global events
  useEffect(() => {
    try {
      const handleRouteChange = () => {
        localStorage.setItem("pageCategory", getPageCategory(router));
      };

      handleRouteChange();

      router.events.on("routeChangeComplete", handleRouteChange);
      return () => {
        router.events.off("routeChangeComplete", handleRouteChange);
      };
    } catch (error) {
      console.error("Error on getPageCategory", error);
    }
  }, [router]);

  return (
    <div>
      <FormsContext.Provider value={{ ga4EventKey, setGa4EventKey }}>
        <Header
          courseID={pageProps?.blog?.course?.id}
          isDetailsPage={pageProps?.isDetailsPage}
          phoneNumber={pageProps?.phoneNumber}
          topBannerData={pageProps?.topBannerData}
          isBot={isBot}
        />
        <ToastifyContainer>
          <ToastContainer />
        </ToastifyContainer>
        <Component {...pageProps} />
      </FormsContext.Provider>
      <AppContext.Provider value={{ pageProps }}>
        <DynamicFooter />
      </AppContext.Provider>
      <div
        id="chatContainer"
        className="fixed right-10px bottom-20 z-99 flex items-end"
      >
        <div className="hidden md:flex items-end">
          <div
            id="chatToolTip"
            className="hidden relative tooltip-bottom chatToolTipDiv"
          >
            <span className="font-bold">
              Hello! Ready to elevate your career? Explore 500+ future-ready
              courses with KnowledgeHut upGrad 🚀
            </span>
            <span
              id="toolTipClose"
              className="cursor-pointer absolute top-0 right-6p"
              onClick={trackPopupClosed}
            >
              x
            </span>
          </div>

          <div
            id="chatButton"
            className="cursor-pointer relative"
            onClick={trackChatIconClick}
          >
            <Image
              src="/chat_icon_desktop.svg"
              alt="Chat bot icon"
              width={56}
              height={56}
            />
            <div
              id="notificationCounter"
              className="hidden absolute top-0 right-0 md:top-1 md:right-1 w-3 h-3 bg-red-600 rounded-full border border-white text-center text-white text-9p font-bold"
            >
              1
            </div>
          </div>
        </div>

        <div id="mobileChat" className="flex items-end md:hidden">
          <div
            id="chatToolTipMobile"
            className="hidden relative tooltip-bottom mr-5p tooltip-bottom"
          >
            <span className="font-bold">
              Fast forward your career with 500+ future-ready courses ⏩💻👩‍🎓📚
            </span>
            <span
              id="toolTipCloseMobile"
              className="cursor-pointer absolute top-0 right-6p"
              onClick={trackPopupClosed}
            >
              x
            </span>
          </div>

          <div
            id="whatsAppChatButton"
            className="cursor-pointer relative"
            onClick={() => trackChatExpensionClick("open")}
          >
            <Image
              src="/whatsapp_phone_icon.svg"
              alt="Whatsapp/Chat icon"
              width={50}
              height={50}
            />
            <div
              id="notificationCounterMobile"
              className="hidden absolute top-0 right-0 md:top-1 md:right-1 w-3 h-3 bg-red-600 rounded-full border border-white text-center text-white text-9p font-bold"
            >
              1
            </div>
          </div>
        </div>

        <div
          id="mobileOptions"
          className="hidden flex-col gap-y-2 md:hidden cursor-pointer relative"
        >
          <a
            href="tel:+918448445027"
            className="phone-icon-circle"
            onClick={trackPhoneIconClick}
          >
            <Image
              className=""
              src="/phoneIcon.svg"
              alt="Phone icon for mobile"
              width={50}
              height={50}
            />
          </a>
          {/* <Image id="chatButtonMobile" className="rounded-full shadow-md" src="/chat_icon_desktop.svg" alt="Chat icon for mobile" width={50} height={50} /> */}
          <a
            id="chatWhatsAppMobile"
            href={`https://api.whatsapp.com/send/?phone=917618793167&text=${encodedAliasMsg}&type=phone_number&app_absent=0`}
            target="_blank"
            rel="noreferrer"
            onClick={trackWhatsappClick}
          >
            <Image
              className="rounded-full shadow-md"
              src="/whatsapp_icon.png"
              alt="Chat icon for mobile"
              width={50}
              height={50}
            />
          </a>
          <Image
            id="chatOptionsCloseMobile"
            className="rounded-full shadow-md"
            src="/chat_close_icon.svg"
            alt="Chat icon for mobile"
            width={50}
            height={50}
            onClick={() => trackChatExpensionClick("close")}
          />
        </div>
      </div>
    </div>
  );
}

export default MyApp;
