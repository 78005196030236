import axios from "axios";
import FormatDate from "../../components/FormatDate";
import FormatDateWithOutTime from "../../components/FormatDateWithOutTime";
import {
  FaqBlocks,
  Blog,
  blogCardList,
  BlogFaqs,
  FaqSubBlocks,
  BlogHowToSchemas,
  HowToSchemaSteps,
  courseSchedules,
  courseEventSchedules,
} from "../../Interfaces/blog";
import { getUserLocationData } from "../common/userLocation";
import { camelCaseConvertor } from "./camelCaseConvertor";
import { IMeta } from "../../Interfaces/meta";

// get blogBySlug
export async function getBlogBySlug(slug: string, status: number) {
  const blogData = await axios.get(`/kh/blogs_list/`, {
    params: { slug: slug, status: status },
    validateStatus: () => true,
  });

  if (blogData.data.result == false) return null;

  return blogData.data.result ? blogData.data.result[0] : null;
}

// getBlogContentByID
export async function getBlogContentByID(
  id: number,
  status?: number,
  user_id?: number
) {
  const blogContent = await axios.get(`/kh/blogs/${id}/content`, {
    params: { status: status, user_id: user_id },
    validateStatus: () => true,
  });

  if (blogContent.data.success == false) return false;

  return camelCaseConvertor(blogContent.data.result);
}

// getBlogTopicBySlug
export async function getBlogTopicBySlug(slug: string) {
  const blogTopic = await axios.get(`/kh/static_blog_topics/${slug}`, {
    headers: { "P-DATA-IDENTIFIER": "slug" },
    validateStatus: () => true,
  });

  if (blogTopic.data.success == false) return null;

  return blogTopic.data.result ? blogTopic.data.result[0] : null;
}

// getBlogsByBlogTopicID
export async function getBlogsByBlogTopicID(
  id: number,
  limit: number,
  offset: number,
  search?: string[]
) {
  const blogslist = await axios.get(`/kh/static_blog_topics/${id}/blogs/`, {
    params: { limit, offset, search },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogCategoryBySlug
export async function getBlogCategoryBySlug(slug: string) {
  const blogCategory = await axios.get(`/kh/blog_categories/${slug}`, {
    headers: { "P-DATA-IDENTIFIER": "slug" },
    validateStatus: () => true,
  });

  if (blogCategory.data.success == false) return null;

  return blogCategory.data.result ? blogCategory.data.result[0] : null;
}

// getBlogsByBlogCategoryID
export async function getBlogsByBlogCategoryID(
  id: number,
  limit: number,
  offset: number,
  search?: string[],
  topic_id?: number
) {
  const blogslist = await axios.get(`/kh/blog_categories/${id}/blogs/`, {
    params: { limit, offset, search, topic_id },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogsForPaginationForHome
export async function getBlogsForPaginationForHome(
  limit: number,
  offset: number,
  s: number,
  load_more?: number,
  search?: string[],
  sort_by?: string,
  topic_id?: number
) {
  const blogslist = await axios.get(`/kh/blogs_home_list`, {
    params: { limit, offset, search, sort_by, static: s, topic_id, load_more },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogTagBySlug
export async function getBlogTagBySlug(slug: string) {
  const blogTags = await axios.get(`/kh/static_blog_tags/${slug}`, {
    headers: { "P-DATA-IDENTIFIER": "slug" },
    validateStatus: () => true,
  });

  if (blogTags.data.success == false) return null;

  return blogTags.data.result ? blogTags.data.result[0] : null;
}

// getBlogsByBlogCategoryID
export async function getBlogsByTagID(
  id: number,
  limit: number,
  offset: number,
  search?: string[]
) {
  const blogslist = await axios.get(`/kh/static_blog_tags/${id}/blogs/`, {
    params: { limit, offset, search },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getAuthorBySlug
export async function getAuthorBySlug(slug: string) {
  const blogAuthors = await axios.get(`/kh/authors/${slug}`, {
    headers: { "P-DATA-IDENTIFIER": "code" },
    validateStatus: () => true,
  });

  if (blogAuthors.data.success == false) return null;

  return blogAuthors.data.result ? blogAuthors.data.result[0] : null;
}

// getBlogsByAuthorID

export async function getBlogsByAuthorID(
  id: number,
  limit: number,
  offset: number,
  search?: string[]
) {
  const blogslist = await axios.get(`/kh/authors/${id}/blogs`, {
    params: { limit, offset },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// Get popular blogs by author id
export async function getPopularBlogsByAuthorID(
  id: number,
  limit: number,
  offset: number,
  search?: string[]
) {
  const blogslist = await axios.get(`/kh/authors/${id}/popular_blogs`, {
    params: { limit, offset },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogsForPagination
export async function getBlogsForPagination(
  limit: number,
  offset: number,
  search?: string,
  sort_by?: string,
  s?: number,
  topic_id?: number
) {
  const blogslist = await axios.get(`/kh/blogs`, {
    params: { limit, offset, search, sort_by, static: s, topic_id },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogsDataList
export async function getBlogsDataList(type: string, limit?: number) {
  const list = await axios.get(`/kh/blogs_data_list`, {
    params: { limit, type },
    validateStatus: () => true,
  });

  if (list.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(list.data.result),
    totalCount: list.data.totalCount,
  };
}

// getMoreSuggestedBlogs
export async function getMoreSuggestedBlogs(
  limit: number,
  offset: number,
  category_id: number,
  blog_id: number,
  load_more_related: number
) {
  const blogslist = await axios.get(`/kh/blogs`, {
    params: { limit, offset, category_id, blog_id, load_more_related },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// getBlogCourseOfferByID
export async function getBlogCourseOfferByID(id: number) {
  const courseOffers = await axios.get(`v1/trainings/courses/${id}/offers`, {
    params: { status: 1 },
    validateStatus: () => true,
  });

  if (courseOffers.data.success == false) return null;

  return courseOffers.data.result ? courseOffers.data.result[0] : null;
}

// getMoreBlogsByTopics
export async function getMoreBlogsByTopicID(
  topic_id: number,
  limit: number,
  offset: number,
  s: number,
  load_more: number
) {
  const blogslist = await axios.get(`/kh/blogs`, {
    params: { limit, offset, static: s, topic_id, load_more },
    validateStatus: () => true,
  });

  if (blogslist.data.success == false) return { result: null, totalCount: 0 };

  return {
    result: camelCaseConvertor(blogslist.data.result),
    totalCount: blogslist.data.totalCount,
  };
}

// blogCardArrangeFOrmat

export function blogCardArrangement(data: any) {
  const blogCardList = data?.map((blog: Blog) => {
    const list = {
      author: blog.author.first_name.concat(" ", blog.author.last_name || ""),
      slug: blog.slug,
      badge: blog.blog_topic?.name || "",
      date: FormatDate(blog.modified, "DD MMM YYYY"),
      description: blog.content.replace(/(<([^>]+)>)/gi, "").substring(0, 200),
      heading: blog.title,
      id: blog.id,
      imageUrl: blog.listing_image || blog.banner_image,
      defaultImageUrl: "assets/blog-default-img.png",
      readingtime: `${blog.mins_of_read}  mins read`,
      viewers: blog.no_of_views,
      viewPageUrl: `/blog/${blog.blog_category.slug}/${blog.slug}`,
      authorPageUrl: `/blog/author/${blog.author.code}`,
      badgeUrl: `/blog/type/${blog.blog_topic?.slug || ""}`,
      altName: blog?.listing_image_alt_text || blog.title,
      blogCategorySlug: blog.blog_category.slug,
      authorProfileImage: blog.author.profile_image,
      created: blog.modified,
      modified: blog.modified,
      published_date: FormatDate(blog.published_date, "DD MMM YYYY"),
    };
    return list;
  });
  return blogCardList;
}

// upDateBlogViewsCount
export async function upDateBlogViewsCount(blogID: number, viewCount: number) {
  const url = `/v1/blogs/${blogID}/update_views`;

  const headers = {
    "Content-type": "application/json",
  };

  const countData = {
    no_of_views: null,
  };

  const response = await axios.put(url, JSON.stringify(countData), {
    headers,
  });

  if (response.data.success == true) {
    return {
      result: {
        message: response.data.result.message,
        status: response.data.success,
      },
    };
  }

  return {
    result: {
      message: "Blog view not updated",
      status: false,
    },
  };
}

// getBlogViewsCount
export async function getBlogViewsCount(blogID: number) {
  const blogContentResult = await axios.get(`/kh/blogs/${blogID}/content`, {
    validateStatus: () => true,
  });

  const previousViewCount =
    blogContentResult.data.result &&
    blogContentResult.data.result.blog.no_of_views;

  return {
    result: {
      count: previousViewCount || 0,
    },
  };
}

// getNewsArticleSnippet
export function getBlogListNewsArticleSnippet(blogs: blogCardList[]) {
  let snippets = blogs.map((blog: blogCardList) => {

    return {
      "@context": "https://schema.org",
      "@type": "Article",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/blog/${blog.blogCategorySlug}/${blog.slug}`,
      },
      headline: blog.heading,
      description: blog.description,
      image: {
        "@type": "ImageObject",
        url: `${process.env.NEXT_PUBLIC_AWS_URI}/${blog.imageUrl || "assets/blog-default-img.png"}`,
      },
      author: {
        "@type": "Person",
        name: blog.author,
        url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}${blog.authorPageUrl}`,
      },
      publisher: {
        "@type": "Organization",
        name: "Knowledgehut",
        logo: {
          "@type": "ImageObject",
          url: "https://www.knowledgehut.com/assets/images_3_0/kh-logo-upgrad.svg",
        },
      },
      datePublished: blog.published_date,
      dateModified: blog.modified,
    };
  });

  return snippets;
}

// getNewsArticleSnippet
export function getNewsArticleSnippet(blog: Blog, meta: IMeta) {

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/blog/${blog.blog_category.slug}/${blog.slug}`,
      "name": blog.title,
    },
    headline: blog.title,
    // description: blog.content.replace(/(<([^>]+)>)/gi, "").substring(0, 200),
    description: meta?.meta_description || meta?.meta_title || blog?.title,
    image: {
      "@type": "ImageObject",
      url: `${process.env.NEXT_PUBLIC_AWS_URI}/${blog?.banner_image || "assets/blog-default-img.png"}`,
      width: blog?.banner_image ? "656" : "700",
      height: blog?.banner_image ? "247" : "300",
    },
    author: {
      "@type": "Person",
      name: blog.author.first_name.concat(" ", blog.author.last_name || ""),
      url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/blog/author/${blog.author.code}`,
    },
    publisher: {
      "@type": "Organization",
      name: "Knowledgehut",
      logo: {
        "@type": "ImageObject",
        url: "https://www.knowledgehut.com/assets/images_3_0/kh-logo-upgrad.svg",
        width: "138",
        height: "44",
      },
    },
    datePublished: blog.published_date,
    dateModified: blog.modified,
  };
}

// getBlogFaqsSnippet
export function getBlogFaqsSnippet(faqs: any) {
  var tifOptions: any = [];
  var allFaqs: any = [];
  var snippets = {};

  if (faqs) {
    faqs?.faq_blocks?.map((key: any, val: any) => {
      Object.keys(key.sub_blocks).forEach(function (key1) {
        tifOptions.push(key.sub_blocks[key1]);
      });
    });

    tifOptions?.map((item_sub_block: any) => {
      if (
        typeof item_sub_block.is_schema != "undefined" &&
        item_sub_block.is_schema == 1
      ) {
        const faqsList = {
          "@type": "Question",
          name: item_sub_block?.label || "",
          acceptedAnswer: {
            "@type": "Answer",
            text: item_sub_block?.description || "",
          },
        };
        allFaqs.push(faqsList);
      }
    });

    if (allFaqs) {
      snippets = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        mainEntity: allFaqs,
      };
    }
  }

  return snippets;
}

// getBlogHowToSchemasSnippet
export function getBlogHowToSchemasSnippet(howToSchemas: any) {
  var allHowToSchemasSteps: any = [];
  var snippets = {};

  const stepsData = howToSchemas.steps_description
    ? JSON.parse(howToSchemas.steps_description)
    : [];
  
  if (stepsData) {
    stepsData?.map((item_step: HowToSchemaSteps) => {
      // comment item_step?.is_schema to avoid rich results error
      /* if (
        typeof item_step?.is_schema != "undefined" &&
        item_step?.is_schema == 1
      ) { */
        const howToSchemasStepsList = {
          "@type": "HowToStep",
          text: item_step?.description || "",
          image: item_step.image
            ? process.env.NEXT_PUBLIC_AWS_URI + "/" + item_step.image
            : "",
          name: item_step?.title || "",
          url: item_step?.url || "",
        };
        allHowToSchemasSteps.push(howToSchemasStepsList);
      // }
    });

    if (howToSchemas) {
      snippets = {
        "@context": "https://schema.org",
        "@type": "HowTo",
        name: howToSchemas?.title || "",
        description: howToSchemas?.description || "",
        image: howToSchemas.image
          ? process.env.NEXT_PUBLIC_AWS_URI + "/" + howToSchemas.image
          : "",
        totalTime: howToSchemas.time_to_complete
          ? howToSchemas.time_to_complete + howToSchemas?.time_measure
          : "",
        estimatedCost: {
          "@type": "MonetaryAmount",
          currency: howToSchemas?.currency?.code || "",
          value: howToSchemas?.estimated_cost_value || "",
        },
        step: allHowToSchemasSteps,
      };
    }
  }

  return snippets;
}

// getBlogFaqsByByID
export async function getBlogFaqsByByID(id: number, status?: number) {
  const blogFaqs = await axios.get(`/v1/blogs/${id}/faqs`, {
    params: { status: status },
    validateStatus: () => true,
  });

  if (blogFaqs.data.success == false) return false;

  return camelCaseConvertor(blogFaqs.data.result);
}

// getBlogHowToSchemasByByID
export async function getBlogHowToSchemasByByID(id: number, status?: number) {
  const blogHowToSchemas = await axios.get(`/v1/blogs/${id}/how_to_schemas`, {
    params: { status: status },
    validateStatus: () => true,
  });
  
  if (blogHowToSchemas.data.success == false) return false;

  return camelCaseConvertor(blogHowToSchemas.data.result);
}

// getBlogCategoryCourseEventsByID
export async function getBlogCategoryCourseEventsByID(
  id: number,
  city: number
) {
  if (city == 0) {
    const locationData = await getUserLocationData();
    city = locationData.city.id;
  }

  const blogsCourseEvents = await axios.get(
    `/kh/blog_categories/${id}/course_events/`,
    {
      params: { bot_city_id: city },
      validateStatus: () => true,
    }
  );

  if (blogsCourseEvents.data.success == false) return false;

  return camelCaseConvertor(blogsCourseEvents.data.result);
}

// getBlogCourseEventsSnippet
export function getBlogCourseEventsSnippet(schedules: courseEventSchedules[]) {
  let snippets =
    schedules &&
    schedules.map((schedule: courseEventSchedules) => {
      if (typeof schedule.session != "undefined") {
        let strDescription = "";
        let strCity = "";
        let strLocation = "";
        let strUri = `${schedule.category_slug}/${schedule.course_slug}`;
        let strScheduleUri = `${strUri}/schedule`;
        let strUrl = `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strScheduleUri}`;

        let strName = schedule.course_name;
        strName = strName.replace("<sup>", "");
        strName = strName.replace("</sup>", "");
        strName = strName.replace(
          "CSM® Certification Training",
          "Certified ScrumMaster® (CSM)"
        );

        let strNameText = "";
        if (
          strName.indexOf("training") > 0 ||
          strName.indexOf("Training") > 0
        ) {
          strNameText = strName;
        } else {
          strNameText = `${strName} training`;
        }

        //$strCity = $objSession->learning_type->name;
        strCity = `Instructor Led Training`;
        strLocation = strName;
        strDescription = `Accelerate your career track with the ${strNameText} from KnowledgeHut. Experience immersive learning with hands-on practice labs, real-time projects, mentor support, auto-graded assessments and assignments.`;
        let strStartTime = `09:00:00`;
        let strEndTime = `05:00:00`;

        let scheduleOffer = {};

        // Offer
        if (
          typeof schedule.session.offer_id != "undefined" &&
          schedule.session.offer_id != null
        ) {
          scheduleOffer = {
            "@type": "AggregateOffer",
            lowPrice: schedule.session.offer_price,
            highPrice: schedule.session.price,
            url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strUri}`,
            availability: "https://schema.org/InStock",
            validFrom: FormatDateWithOutTime(
              schedule.session.offer_start_date,
              "YYYY-MM-DD"
            ),
            price: schedule.session.offer_price,
            priceCurrency:
              typeof schedule.session.currency_name != "undefined"
                ? schedule.session.currency_name
                : "",
          };
        } else {
          scheduleOffer = {
            "@type": "AggregateOffer",
            lowPrice: schedule.session.price,
            highPrice: schedule.session.price,
            url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strUri}`,
            availability: "https://schema.org/InStock",
            validFrom: FormatDateWithOutTime(
              schedule.session.created,
              "YYYY-MM-DD"
            ),
            price: schedule.session.price,
            priceCurrency:
              typeof schedule.session.currency_name != "undefined"
                ? schedule.session.currency_name
                : "",
          };
        }

        let eventCourse = {
          "@context": "https://schema.org",
          "@type": "EducationEvent",
          name: "📆 " + strip_html_tags(strName),
          description: strip_html_tags(strDescription),
          performer: "Knowledgehut",
          organizer: {
            type: "Organization",
            name: "Knowledgehut",
            url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strUri}`,
          },
          image: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/assets/images/logo/knowledgehut-logo.png`,
          eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
          eventStatus: "https://schema.org/EventScheduled",
          location: [
            {
              "@type": "VirtualLocation",
              url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strUri}`,
            },
            {
              "@type": "Place",
              name: "Bangalore",
              address: {
                "@type": "PostalAddress",
                streetAddress:
                  " 6th Floor, Vaishnavi Tech Park, 16/1, Ambalipura - Sarjapur Rd, Bellandur, Bengaluru, Karnataka 560103",
                addressLocality: "Bangalore",
                addressRegion: "Karnataka",
                addressCountry: "India",
              },
            },
          ],
          offers: scheduleOffer,
          startDate: FormatDate(
            schedule.session.start_date + " " + strStartTime,
            "YYYY-MM-DD"
          ),
          endDate: FormatDate(
            schedule.session.end_date + " " + strEndTime,
            "YYYY-MM-DD"
          ),
          duration: schedule.session.no_of_days,
          url: `${process.env.NEXT_PUBLIC_WEB_DOMAIN}/${strUri}`,
        };

        return eventCourse;
      }
    });

  return snippets && snippets.filter((item) => item != null);
}

function strip_html_tags(str: string) {
  if (str === null || str === "") {
    return false;
  } else {
    str = str.toString();
  }

  return str.replace(/<[^>]*>/g, "");
}

// getWhatsAppLinkDataByParams
export async function getWhatsAppLinkDataByParams(
  courseID: number,
  countryID: number
) {
  //whatsapp success popup shown courseIDs
  const whatsappSuccessPopupCourses = [
    21, 30, 224, 22, 31, 168, 31, 39, 274, 80, 75, 83, 415, 416, 590, 770, 313,
    842,
  ];

  if (whatsappSuccessPopupCourses.includes(courseID)) {
    const whatsAppContent = await axios.get(`/kh/lead_social_links`, {
      params: { course_id: courseID, country_id: countryID },
      validateStatus: () => true,
    });

    if (whatsAppContent.data.success == false) return false;

    return camelCaseConvertor(whatsAppContent.data.result);
  } else {
    return false;
  }
}

export async function getSocialLinks(courseID: number, countryID: number) {
  const result = await axios.get(`/kh/lead_social_links`, {
    params: { course_id: courseID, country_id: countryID },
    validateStatus: () => true,
  });
  if (result.data.success == false) return null;
  return camelCaseConvertor(result.data.result);
}
