import HeaderLargeScreen from "./HeaderLargeScreen";
import HeaderSmallScreen from "./HeaderSmallScreen";
import TopBanner from "../TopBanner";
import HeaderTop from "./HeaderTop";
import { useEffect, useState } from "react";
import { getBlogLocation } from "../../lib/apis/utm";
import { getUserLocationData } from "../../lib/common/userLocation";
import EnquiryNumber from "../EnquiryNumber";

interface PostsProps {
  courseID: number;
  isDetailsPage?: boolean;
  phoneNumber?: string;
  topBannerData?: any;
  isBot?: boolean;
}

const Header: React.FC<PostsProps> = ({
  courseID,
  isDetailsPage,
  phoneNumber,
  topBannerData,
  isBot,
}) => {
  const [showTopBanner, setshowTopBanner] = useState<boolean>(true);
  const [isInternational, setIsInterNational] = useState<boolean>(true);

  useEffect(() => {
    async function getCounrtyLocation() {
      let countryLocationData = await getUserLocationData();

      setshowTopBanner(
        countryLocationData.country.slug == "singapore" ? false : true
      );

      // setIsInterNational(
      //   countryLocationData.country.slug == "india" ? false : true
      // );
    }
    getCounrtyLocation();
  }, []);

  return (
    <>
      {phoneNumber && <EnquiryNumber phoneNumber={phoneNumber} />}
      {topBannerData?.length > 0 && (
        <TopBanner courseID={courseID} topBannerData={topBannerData[0]} />
      )}
      <div className="hidden 2xl:block">
        <HeaderTop phoneNumber={phoneNumber} />
      </div>
      <header
        className={`bg-white 2xl:flex 2xl:items-center px-2 xl:px-2 md:h-57p xs:h-57p ${
          isDetailsPage
            ? "border-b-0 sm:border-b sm:border-gray-200"
            : "border-b border-gray-200"
        }`}
      >
        <HeaderLargeScreen courseID={courseID} isBot={isBot} />
        <HeaderSmallScreen courseID={courseID} isBot={isBot} />
      </header>
    </>
  );
};

export default Header;
