import Link from "next/link";
import { useState } from "react";
import BlogSubscribeModal from "../BlogSubscribeModal";
import Button from "../Button/index";
import MegaMenu from "../MegaMenu";
import MenuItem from "../MenuItems";
import { menuItemDetails } from "../MenuItems/Data";
import { MenuItemsProps } from "../MenuItems/Interface";
import HeaderIcon from "./HeaderIcon";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

type Props = {
  MenuItemsProps: MenuItemsProps;
  MegaMenuProps: any;
};

type HeaderProp = {
  courseID?: number;
  isBot?: boolean;
};

const HeaderLargeScreen: React.FC<HeaderProp> = ({ courseID, isBot }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="container">
        <nav className="hidden 2xl:grid 2xl:grid-cols-2">
          <BlogSubscribeModal
            open={open}
            setOpen={setOpen}
            courseID={courseID}
          />
          <div className="flex items-center">
            {/* Header Image */}
            <HeaderIcon />

            {/* MegaMenu */}
            {/* <div className="pr-3 pl-10">
            <MegaMenu />
          </div> */}

            {/* Back to Blogs link */}
            <div className="ml-12">
              <Link href="/blog">
                {/* <a className="flex items-center px-3">
                <i className="blog-icons inline-block icon-home-black" />
                <span className="pl-2 text-13 text-gray-5 font-semibold">
                  Back to Blogs
                </span>
              </a> */}
                <a
                  className=""
                  onClick={() => {
                    trackGa4Events("click-all-blogs-top-nav");
                  }}
                >
                  {/* All blogs */}
                  <div className=" w-20 h-9 py-2 flex items-center  rounded bg-gray-13 border-transparent px-3   leading-5 font-semibold text-13 ">
                    All Blogs
                  </div>
                </a>
              </Link>
            </div>
          </div>

          <div className="flex justify-end items-center">
            {/* Menu items */}
            <MenuItem toShow={3} data={menuItemDetails} isBot={isBot} />

            {/* Subscribe Button */}

            <div
              className="px-1"
              onClick={() => {
                setOpen(!open);
                trackGa4Events("click-subscribe-top-nav");
              }}
            >
              {/* <Button text="Subscribe" variant="contained" /> */}
              <button className="inline-flex items-center justify-center h-9  text-base sm:text-13 leading-5 font-semibold text-black bg-gray-13 px-3  py-2 rounded">
                Subscribe
              </button>
            </div>

            {/* SignIn button */}
            {/* <div className="px-1">
            <Button text="Sign In" variant="hoverOutlined" />
          </div> */}
          </div>
        </nav>
      </div>
    </>
  );
};

export default HeaderLargeScreen;
