import { useState } from "react";
import { MenuItemsProps, MenuItemsData } from "./Interface";
import SubMenuItems from "./SubMenuitems";
import { trackGa4Events } from "../../lib/events/ga4/tracker";

const TopHeaderMenuItems: React.FC<MenuItemsProps | any> = ({
  toShow,
  data,
  title,
  isBot,
}) => {
  const [isShowMore, SetIsShowMore] = useState(isBot ? true : false);

  return (
    data.length && (
      <div
        className={`flex items-center justify-evenly  ${
          title == "Resources" ? "mr-0" : "mr-5"
        }`}
      >
        {data
          ?.slice(0, toShow)
          .map(({ ref, title }: MenuItemsData, index: number) => (
            <a href={ref} key={index}>
              <div className="py-5 px-2 mx-2 text-xs text-gray-5 font-semibold  cursor-pointer ">
                {title}
              </div>
            </a>
          ))}
        {data.length > toShow && (
          <div className="group relative -mt-2p ">
            <div
              className="relative inline-flex item-center  text-gray-5 text-xs font-normal  cursor-pointer"
              onMouseEnter={() => {
                SetIsShowMore(true);
                trackGa4Events(`hover-header-menu{${title}}`);
              }}
              onMouseLeave={() => SetIsShowMore(false)}
            >
              {title || "More "}

              <div className="pl-1 -mt-1p pb-6p">
                <i className="blog-header-arrow-down-icon inline-block"></i>
              </div>
            </div>
            {isShowMore && (
              <div
                className={`${title === "Resources" ? "right-0" : ""} ${
                  title === "Enterprise" ? " w-52" : ""
                } ${
                  title === "Resources" ? "w-215" : ""
                } w-72  absolute z-50 flex-col hidden bg-white rounded-sm shadow-md group-hover:block duration-[0.3s]`}
                onMouseEnter={() => SetIsShowMore(true)}
              >
                {data
                  ?.slice(toShow)
                  .map(({ ref, title, data }: MenuItemsData, index: number) =>
                    data ? (
                      // Menu inside menu
                      <SubMenuItems
                        key={index}
                        title={title}
                        data={data}
                        toShow={toShow}
                        isBot={isBot}
                      />
                    ) : (
                      // Single menu

                      <a href={ref} key={index}>
                        <div className="py-2 px-3 border-b border-gray-100 text-xs  cursor-pointer hover:bg-gray-100">
                          {title}
                        </div>
                      </a>
                    )
                  )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  );
};
export default TopHeaderMenuItems;
