import isbot from "isbot";

const isBotUser = (reqUserAgent: string) => {
  let botsRegex = [
    "Googlebot",
    "Yahoo! Slurp",
    "bingbot",
    "YandexBot",
    "Googlebot-Mobile",
    "Google-InspectionTool"
  ];
  for (let i = 0; i < botsRegex.length; i++) {
    if (reqUserAgent.includes(botsRegex[i])) {
      return true;
    }
  }
  return false;
};

export default isBotUser;
